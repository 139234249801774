import React from "react"
import styled from "styled-components"
import nyugdijas from "../images/nyugdij.jpg"
import ahfsz from "../images/szakszerv.png"

const Discounts = () => {
  return (
    <StyledDiscounts id="discounts">
      <div className="container">
        <h1 className="title">Kedvezmények</h1>
        <div className="discount-container">
          <img className="logo" src={nyugdijas} alt="nyugdíjas" />
          <h3 className="description">
            NYUGDÍJASOK részére a munkadíjból 10% kedvezményt adunk! <br />
            Továbbá komplett családi ház esetén, lehetőséget biztosítunk a
            teljes felújítási munkálatok több részletben történő kivitelezésre.
          </h3>
        </div>
        <div className="discount-container">
          <img
            className="logo"
            src={ahfsz}
            alt="Audi Hungária Független Szakszervezet"
          />
          <h3 className="description">
            Az AUDI Hungária Független Szakszervezet tagjai (a tagkártya
            bemutatása esetén) a munkadíjból 10 % kedvezményben részesülnek!
          </h3>
        </div>
      </div>
    </StyledDiscounts>
  )
}

export default Discounts

const StyledDiscounts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }

  .title {
    color: rgb(57, 62, 70);
    font-size: 4rem;
    font-weight: 500;
    margin: 2rem;
    text-shadow: 3px 3px 3px rgb(134, 168, 164);
  }

  .discount-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
  }

  .logo {
    max-width: 190px;
    height: auto;
    margin-right: 3rem;
  }

  .description {
    color: rgb(57, 62, 70);
    font-weight: 400;
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 768px) {
    .title {
      font-size: 2.5rem;
    }

    .logo {
      max-width: 120px;
      height: auto;
      margin-right: 2rem;
    }

    .description {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .description {
      font-size: 1rem;
    }

    .logo {
      max-width: 110px;
      height: auto;
      margin-right: 1.5rem;
    }
  }
`
