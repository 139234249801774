import React from "react"
import styled from "styled-components"
import ImageGallery from "react-image-gallery"

import kep1 from "../images/gallery/1 - Új villamoshálózat kialakítása.jpg"
import kep2 from "../images/gallery/2 - Új ház villamoshálózatának kiépítése.jpg"
import kep3 from "../images/gallery/3 - Átalakítás-bővítés.jpg"
import kep4 from "../images/gallery/4 - Elektromos tűz.jpg"
import kep5 from "../images/gallery/5 - Elavult biztosítéktábla cseréje.jpg"
import kep6 from "../images/gallery/6 - Bojler vízkőtlenítése.jpg"
import kep7 from "../images/gallery/7 - Bojlerben felgyülemlett vízkő.jpg"
import kep8 from "../images/gallery/8 - Bojler felújított alaplap.jpg"
import kep9 from "../images/gallery/9 - Hibafeltárás.jpg"
import kep10 from "../images/gallery/10 - Kábelek rendszerezése.jpg"
import kep11 from "../images/gallery/11 - Korszerűsítés.jpg"
import kep12 from "../images/gallery/12 - Helyreállítás előtte-utána.jpg"
import kep13 from "../images/gallery/13 - Biztosítéktábla.jpg"
import kep14 from "../images/gallery/14 - Kötődoboz.jpg"
import kep15 from "../images/gallery/15 - EPH csomópont.jpg"
import kep16 from "../images/gallery/16 - Gázkazán.jpg"
import kep17 from "../images/gallery/17 - Elektromos autó töltő.jpg"
import kep18 from "../images/gallery/18 - Elektromos autó töltő.jpg"

const images = [
  {
    original: `${kep1}`,
    thumbnail: `${kep1}`,
    thumbnailAlt: "Új villamoshálózat kialakítása",
    description: "Új villamoshálózat kialakítása",
    originalAlt: "Új villamoshálózat kialakítása",
    originalClass: "images",
  },
  {
    original: `${kep2}`,
    thumbnail: `${kep2}`,
    thumbnailAlt: "Új ház villamoshálózatának kiépítése",
    description: "Új ház villamoshálózatának kiépítése",
    originalAlt: "Új ház villamoshálózatának kiépítése",
    originalClass: "images",
  },
  {
    original: `${kep3}`,
    thumbnail: `${kep3}`,
    thumbnailAlt: "Átalakítás - bővítés",
    description: "Átalakítás - bővítés",
    originalAlt: "Átalakítás - bővítés",
    originalClass: "images",
  },
  {
    original: `${kep4}`,
    thumbnail: `${kep4}`,
    thumbnailAlt: "Elektromos tűz utáni helyreállítás",
    description: "Elektromos tűz utáni helyreállítás",
    originalAlt: "Elektromos tűz utáni helyreállítás",
    originalClass: "images",
  },
  {
    original: `${kep5}`,
    thumbnail: `${kep5}`,
    thumbnailAlt: "Elavult biztosítéktábla cseréje",
    description: "Elavult biztosítéktábla cseréje",
    originalAlt: "Elavult biztosítéktábla cseréje",
    originalClass: "images",
  },
  {
    original: `${kep6}`,
    thumbnail: `${kep6}`,
    thumbnailAlt: "Bojler vízkőtelenítése",
    description: "Bojler vízkőtelenítése",
    originalAlt: "Bojler vízkőtelenítése",
    originalClass: "images",
  },
  {
    original: `${kep7}`,
    thumbnail: `${kep7}`,
    thumbnailAlt: "Bojlerben felgyülemlett vízkő",
    description: "Bojlerben felgyülemlett vízkő",
    originalAlt: "Bojlerben felgyülemlett vízkő",
    originalClass: "images",
  },
  {
    original: `${kep8}`,
    thumbnail: `${kep8}`,
    thumbnailAlt: "Bojler felújított alaplap",
    description: "Bojler felújított alaplap",
    originalAlt: "Bojler felújított alaplap",
    originalClass: "images",
  },
  {
    original: `${kep9}`,
    thumbnail: `${kep9}`,
    thumbnailAlt: "Hibafeltárás",
    description: "Hibafeltárás",
    originalAlt: "Hibafeltárás",
    originalClass: "images",
  },
  {
    original: `${kep10}`,
    thumbnail: `${kep10}`,
    thumbnailAlt: "Kábelek rendszerezése",
    description: "Kábelek rendszerezése",
    originalAlt: "Kábelek rendszerezése",
    originalClass: "images",
  },
  {
    original: `${kep11}`,
    thumbnail: `${kep11}`,
    thumbnailAlt: "Korszerűsítés",
    description: "Korszerűsítés",
    originalAlt: "Korszerűsítés",
    originalClass: "images",
  },
  {
    original: `${kep12}`,
    thumbnail: `${kep12}`,
    thumbnailAlt: "Helyreállítás előtte-utána",
    description: "Helyreállítás előtte-utána",
    originalAlt: "Helyreállítás előtte-utána",
    originalClass: "images",
  },
  {
    original: `${kep13}`,
    thumbnail: `${kep13}`,
    thumbnailAlt: "Biztosítéktábla",
    description: "Biztosítéktábla",
    originalAlt: "Biztosítéktábla",
    originalClass: "images",
  },
  {
    original: `${kep14}`,
    thumbnail: `${kep14}`,
    thumbnailAlt: "Kötődoboz",
    description: "Kötődoboz",
    originalAlt: "Kötődoboz",
    originalClass: "images",
  },
  {
    original: `${kep15}`,
    thumbnail: `${kep15}`,
    thumbnailAlt: "EPH csomópont",
    description: "EPH csomópont",
    originalAlt: "EPH csomópont",
    originalClass: "images",
  },
  {
    original: `${kep16}`,
    thumbnail: `${kep16}`,
    thumbnailAlt: "Gázkazán",
    description: "Gázkazán",
    originalAlt: "Gázkazán",
    originalClass: "images",
  },
  {
    original: `${kep17}`,
    thumbnail: `${kep17}`,
    thumbnailAlt: "Elektromos autó töltő",
    description: "Elektromos autó töltő",
    originalAlt: "Elektromos autó töltő",
    originalClass: "images",
  },
  {
    original: `${kep18}`,
    thumbnail: `${kep18}`,
    thumbnailAlt: "Elektromos autó töltő",
    description: "Elektromos autó töltő",
    originalAlt: "Elektromos autó töltő",
    originalClass: "images",
  },
]

const Gallery = () => {
  return (
    <StyledGallery id="gallery">
      <h1 className="title">Galéria</h1>
      <ImageGallery
        className="image-gallery"
        items={images}
        lazyLoad={true}
        showBullets={true}
      />
    </StyledGallery>
  )
}

export default Gallery

const StyledGallery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(57, 62, 70);
  padding-bottom: 2rem;
  .image-gallery {
    width: 90vw;
    height: auto;
  }

  .image-gallery-image {
    height: 500px;
    width: 500px;
  }

  /*
  .images {
    width: 100%;
    height: auto;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
  }
*/
  .title {
    color: rgb(255, 255, 255);
    font-size: 4rem;
    font-weight: 500;
    margin: 2rem;
    text-shadow: 3px 3px 3px rgb(38, 41, 46);
  }

  @media only screen and (max-width: 768px) {
    .title {
      font-size: 2.5rem;
      margin: 1rem;
    }
    .image-gallery-image {
      height: 400px;
      width: 400px;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 1rem;
    .image-gallery-image {
      height: 300px;
      width: 300px;
    }
  }
  @media only screen and (max-width: 320px) {
    .image-gallery-image {
      height: 250px;
      width: 250px;
    }
  }
`
