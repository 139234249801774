import React from "react"
import styled from "styled-components"
import kapcsoloszekreny from "../images/kapcsoloszekreny.jpg"

const About = () => {
  return (
    <StyledAbout id="about">
      <div className="container">
        <div className="aboutus">
          <h2 className="title">Rólunk</h2>
          <p className="description">
            Vállalkozásunk Győr és 30 km-es vonzáskörzetében vállal
            villanyszerelési munkálatokat.
          </p>

          <p className="description">
            Fő tevékenységünk a régi és új házak, lakások teljes körű
            villanyszerelése, ugyanakkor vállaljuk egyéb villanyszerelési munkák
            elvégzését is, mint:
          </p>
          <ul className="list">
            <li>teljesítménybővítés (E.ON ügyintézés)</li>
            <li>villanyóra áthelyezés (E.ON ügyintézés)</li>
            <li>biztonsági felülvizsgálat</li>
            <li>elektromos autó töltő kiépítése</li>
            <li>EPH kiépítése (gázkazánok, ipari polcok, stb...)</li>
            <li>klíma előkészítése</li>
            <li>fűtésvezérlés</li>
            <li>elektromos kapuk, garázsajtók</li>
            <li>érintésvédelmi felülvizsgálat</li>
            <li>túlfeszültség-védelem kialakítása</li>
            <li>biztosítéktábla cseréje</li>
            <li>kapcsolók, konnektorok, egyéb szerelvények cseréje</li>
            <li>lámpák, LED szalagok, LED panelek szerelése</li>
            <li>bojlerek vízkőtelenítése, karbantartása, javítása</li>
            <li>háztartási berendezések/gépek üzembe helyezése</li>
            <li>hibák elhárítása</li>
          </ul>
          <p className="description">
            Munkáinkat az egyedi igények figyelembevételével végezzük, ügyelve a
            biztonságra, tekintettel a praktikusságra, megjelenésre. A
            felújítási munkálatok során a legkorszerűbb szerelési technikákat
            alkalmazzuk.
          </p>
          <p className="description">
            Kizárólag újszerű, minőségi anyagokkal dolgozunk, elérhető áron!
          </p>
          <p className="description">
            Az elvégzett munkákra garanciát vállalunk!
          </p>
          <p className="description">
            Célunk, hogy Megrendelőink mindig magas színvonalú szolgáltatásban
            részesüljenek, így számunkra elengedhetetlen a precíz,
            lelkiismeretes munka, a határidők pontos betartása mellett!
          </p>
          <p className="description">
            Amennyiben felkeltettük érdeklődését, keressen minket bizalommal
            elérhetőségeinken!
          </p>
        </div>
        <div className="aboutpic">
          <img src={kapcsoloszekreny} alt="kapcsoloszekreny" />
        </div>
      </div>
    </StyledAbout>
  )
}

export default About

const StyledAbout = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(57, 62, 70);

  .container {
    width: 100%;
    height: 800px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  .title {
    color: rgb(255, 255, 255);
    font-size: 4rem;
    font-weight: 500;
    align-self: flex-start;
    margin-bottom: 0.5rem;
  }

  .aboutus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
  }

  .aboutpic {
    align-self: flex-end;
    height: 800px;
    max-width: 50%;
  }

  .description {
    color: rgb(255, 255, 255);
    margin-bottom: 0.4rem;
  }

  .list {
    padding-left: 2rem;
    margin-bottom: 0.5rem;
  }

  .list li {
    color: rgb(255, 255, 255);
  }

  @media only screen and (max-width: 1024px) {
    .title {
      font-size: 3rem;
    }

    .aboutus {
      padding: 1.5rem;
    }

    .description {
      font-size: 0.9rem;
    }

    .list li {
      font-size: 0.9rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .title {
      font-size: 2.5rem;
    }

    .container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .aboutpic {
      min-width: 100vw;
      height: 30vh;
      overflow: hidden;
    }

    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 480px) {
    .title {
      margin-bottom: 0.5rem;
    }

    .description {
      font-size: 0.8rem;
    }

    .list li {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 320px) {
    .title {
      font-size: 2.5rem;
      margin-bottom: 0.3rem;
    }

    .description {
      font-size: 0.7rem;
    }

    .list li {
      font-size: 0.7rem;
    }
  }
`
