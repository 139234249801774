import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faRulerCombined,
  faCalculator,
  faSearch,
  faHandsHelping,
  faFileInvoice,
  faTools,
} from "@fortawesome/free-solid-svg-icons"
import biztositek from "../images/biztositek.jpg"

const Services = () => {
  return (
    <StyledServices id="services">
      <h2 className="title">Szolgáltatásaink</h2>
      <div className="container">
        <div className="services-img-container">
          <img src={biztositek} alt="biztositek" className="services-img" />
        </div>
        <div className="services-list">
          <div className="service">
            <span className="services-icon-container">
              <FontAwesomeIcon className="service-icon" icon={faSearch} />
            </span>
            <div className="service-description">
              <h4>Igényfelmérés</h4>
              <p>
                Segítünk önnek a legmegfelelőbb technológia kiválasztásában.
              </p>
            </div>
          </div>
          <div className="service">
            <span className="services-icon-container">
              <FontAwesomeIcon
                className="service-icon"
                icon={faRulerCombined}
              />
            </span>
            <div className="service-description">
              <h4>Tervezés</h4>
              <p>Kivitelezés előtt segítünk megtervezni elképzeléseit.</p>
            </div>
          </div>
          <div className="service">
            <span className="services-icon-container">
              <FontAwesomeIcon className="service-icon" icon={faHandsHelping} />
            </span>
            <div className="service-description">
              <h4>Tanácsadás</h4>
              <p>
                Szakmai kérdések megválaszolásában kollégáink készséggel állnak
                rendelkezésre.
              </p>
            </div>
          </div>
          <div className="service">
            <span className="services-icon-container">
              <FontAwesomeIcon className="service-icon" icon={faCalculator} />
            </span>
            <div className="service-description">
              <h4>Árajánlat készítése</h4>
              <p>Ingyenes árajánlatért hívjon minket bizalommal!</p>
            </div>
          </div>
          <div className="service">
            <span className="services-icon-container">
              <FontAwesomeIcon className="service-icon" icon={faTools} />
            </span>
            <div className="service-description">
              <h4>Kivitelezés</h4>
              <p>
                Az alapanyagok beszerzésétől a készre szerelésig elvégezzük a
                munkát.
              </p>
            </div>
          </div>
          <div className="service">
            <span className="services-icon-container">
              <FontAwesomeIcon className="service-icon" icon={faFileInvoice} />
            </span>
            <div className="service-description">
              <h4>Garancia</h4>
              <p>Munkánkra garanciát vállalunk.</p>
            </div>
          </div>
        </div>
      </div>
    </StyledServices>
  )
}

export default Services

const StyledServices = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffff;
  padding: 2rem 0;

  .container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 1rem;
    width: 85%;
  }

  .title {
    color: rgb(57, 62, 70);
    font-size: 4rem;
    font-weight: 500;
    margin: 2rem;
    text-shadow: 3px 3px 3px rgb(134, 168, 164);
  }

  .services-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  .services-list {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 1rem;
  }

  .service {
    display: flex;
    align-items: center;
    height: 110px;
    background: rgba(57, 62, 70, 1) 0%;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }

  .service:hover {
    .service-icon {
      transform: scale(1.25);
    }
  }

  .services-icon-container {
    background: #858484;
    min-width: 107px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .service-icon {
    color: #ffff;
    min-width: 40px;
    height: 100%;
    transition: transform 0.5s;
  }

  .service-description {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 0.5rem;
    h4 {
      color: #ffff;
      font-size: 1.6rem;
      font-weight: 400;
    }

    p {
      color: #ffff;
      font-size: 0.8rem;
      font-weight: 200;
    }
  }
  @media only screen and (max-width: 1024px) {
    .container {
      grid-template-columns: 1fr;
      padding: 2rem;
    }
    .services-img-container {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .title {
      font-size: 2.5rem;
    }
    .services-list {
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
    }
    .container {
      padding: 1rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .container {
      padding: 0;
    }
  }
`
