import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"
import whiteLogo from "../images/logoWhite.png"
import facebookLogo from "../images/facebookLogo.png"
import instagramLogo from "../images/instagramLogo.png"

const Contact = () => {
  return (
    <StyledContact id="contact">
      <div className="container">
        <div className="contacts">
          <div className="wrapper description">
            <h1 className="title">Kapcsolat</h1>
            <p>
              Vállalkozásunk Győr és vonzáskörzetében vállal villanyszerelési
              munkálatokat. Fő tevékenységünk a régi és új házak, lakások teljes
              körű villanyszerelése, de vállaljuk a kisebb munkálatok elvégzését
              is.
              <br />
              Keressen minket bizalommal elérhetőségeinken!
            </p>
          </div>
          <div className="wrapper">
            <div className="phone-container">
              <h4>
                <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
                Telefon:
              </h4>
              <a href="tel:+36 12 345 6789">
                <h3 className="phone">+36 30 651 3804</h3>
              </a>
            </div>
            <div className="email-container">
              <h4>
                <FontAwesomeIcon icon={faEnvelope} className="icon" />
                E-mail:
              </h4>
              <a href="mailto:fazisohm@gmail.com">
                <h3 className="email">fazisohm@gmail.com</h3>
              </a>
            </div>
            <div className="social-container">
              <a href="https://www.facebook.com/F%C3%A1zisohm-Villanyszerel%C3%A9s-105371245220433">
                <img
                  src={facebookLogo}
                  alt="facebook-logo"
                  className="social-logo"
                />
              </a>
              <a href="https://www.instagram.com/fazisohm/">
                <img
                  src={instagramLogo}
                  alt="twitter-logo"
                  className="social-logo"
                />
              </a>
            </div>
          </div>
          <div className="logo-container">
            <img className="logo" src={whiteLogo} alt="logo" />
          </div>
        </div>

        <span className="footer">
          Fázisohm {new Date().getFullYear()} Minden jog fenntartva.
        </span>
      </div>
    </StyledContact>
  )
}

export default Contact

const StyledContact = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: rgb(57, 62, 70);
  padding-bottom: 0.5rem;

  .container {
    width: 80%;
    padding-top: 2rem;
  }

  .title {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-shadow: 3px 3px 3px rgb(38, 41, 46);
  }

  .contacts {
    display: flex;
    align-items: space-between;
    justify-content: space-between;
  }

  .description {
    max-width: 25vw;
    margin-bottom: 1rem;
    p {
      color: #ffff;
      font-weight: 300;
    }
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 15vw;
  }

  .logo {
    width: 300px;
    height: 200px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .phone-container {
    text-align: center;
  }

  .email-container {
    text-align: center;
  }

  h4 {
    color: rgb(255, 255, 255);
    font-weight: 300;
    margin-bottom: 0.5rem;
    .icon {
      margin-right: 0.5rem;
    }
  }

  h3 {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .phone:hover {
    color: rgba(0, 173, 181, 1);
  }

  .email:hover {
    color: rgba(0, 173, 181, 1);
  }

  .link:hover {
    color: rgba(0, 173, 181, 1);
  }

  .social-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .social-logo {
    width: 35px;
    height: 35px;
    margin-right: 1rem;
    transition: all 0.2s ease-in-out;
  }

  .social-logo:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  .social-logo:active {
    transform: scale(0.9);
  }

  .footer {
    color: #ffff;
    font-size: 0.7rem;
    font-weight: 300;
  }

  @media only screen and (max-width: 768px) {
    h1 {
      text-align: center;
    }

    p {
      text-align: center;
      margin-bottom: 1rem;
    }

    .title {
      font-size: 2.5rem;
      margin: 1rem 0;
    }

    .contacts {
      flex-direction: column;
      align-items: center;
    }

    .logo-container {
      min-width: 70vw;
      padding: 0.5rem;
    }

    .email {
      margin-bottom: 1rem;
    }

    .description {
      max-width: 70vw;
      margin-bottom: 1rem;
    }

    .footer {
      display: flex;
      justify-content: center;
    }
  }
`

//
