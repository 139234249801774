import React, { useState } from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import whiteLogo from "../images/logoremake.png"

const Navbar = () => {
  const [open, setOpen] = useState(false)

  return (
    <StyledNav>
      <div className="logo-container">
        <img className="logo" src={whiteLogo} alt="logo" />
      </div>
      <div className="links" id={!open ? "" : "hidden"}>
        <button onClick={() => setOpen(false)}>
          <AnchorLink to="/#index" className="link">
            Kezdőlap
          </AnchorLink>
        </button>
        <button onClick={() => setOpen(false)}>
          <AnchorLink to="/#about" className="link">
            Rólunk
          </AnchorLink>
        </button>
        <button onClick={() => setOpen(false)}>
          <AnchorLink to="/#services" className="link">
            Szolgáltatásaink
          </AnchorLink>
        </button>
        <button onClick={() => setOpen(false)}>
          <AnchorLink to="/#discounts" className="link">
            Kedvezmények
          </AnchorLink>
        </button>
        <button onClick={() => setOpen(false)}>
          <AnchorLink to="/#gallery" className="link">
            Galéria
          </AnchorLink>
        </button>
        <button onClick={() => setOpen(false)}>
          <AnchorLink to="/#partners" className="link">
            Partnereink
          </AnchorLink>
        </button>
        <button onClick={() => setOpen(false)}>
          <AnchorLink to="/#contact" className="link">
            Kapcsolat
          </AnchorLink>
        </button>
      </div>
      <FontAwesomeIcon
        className="mobile-menu"
        icon={!open ? faBars : faTimes}
        onClick={() => setOpen(!open)}
        style={{ color: "black" }}
      />
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(212, 0, 0);
  position: sticky;
  top: 0;
  height: 100px;
  padding-left: 2rem;
  padding-right: 4rem;
  z-index: 10;

  button {
    background: none;
    border: none;
  }

  .logo-container {
    display: flex;
  }

  .logo {
    height: 100px;
    width: 140px;
  }

  .links {
    display: flex;
  }

  .link {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 1.2rem;
    color: #000000;
  }
  .link:hover {
    color: #ffff;
  }
  .link:focus {
    color: #ffff;
  }

  .mobile-menu {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    .link {
      font-size: 1rem;
      margin-left: 1.2rem;
    }
    padding-left: 0;
    padding-right: 2rem;
    .mobile-menu {
      display: flex;
      min-width: 2rem;
      height: auto;
      transition: all 0.1s ease-in-out;
      cursor: pointer;
    }

    .mobile-menu {
      color: blue;
    }

    .mobile-menu:hover {
      transform: scale(1.2);
    }

    .links {
      display: none;
    }

    #hidden {
      position: absolute;
      left: 0;
      top: 95px;
      height: 250px;
      width: 100%;
      background-color: rgb(212, 0, 0);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 1.5rem;
    }

    #hidden .link {
      font-size: 1.5rem;
    }
  }
`

export default Navbar
