import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faToolbox } from "@fortawesome/free-solid-svg-icons"

const Partners = () => {
  return (
    <StyledPartners id="partners">
      <div className="container">
        <div className="titles">
          <h1 className="title">Partnereink</h1>
          <h3 className="alt-title">
            Akikkel szívesen dolgozunk, és bátran ajánljuk őket!
          </h3>
        </div>
        <div className="partner-list">
          <div className="partner">
            <span className="partner-icon-container">
              <FontAwesomeIcon className="partner-icon" icon={faToolbox} />
            </span>
            <div className="partner-description">
              <h4>Záborszki Tamás - Kőműves</h4>
              <div>
                <a href="tel:+36 20 369 2406">
                  <p className="phone">+36 20 369 2406</p>
                </a>
              </div>
            </div>
          </div>
          <div className="partner">
            <span className="partner-icon-container">
              <FontAwesomeIcon className="partner-icon" icon={faToolbox} />
            </span>
            <div className="partner-description">
              <h4>Halász Zoltán - Klímaszerelés</h4>
              <div>
                <a href="tel:+36 70 311 3773">
                  <p className="phone">+36 70 311 3773</p>
                </a>
              </div>
            </div>
          </div>
          <div className="partner">
            <span className="partner-icon-container">
              <FontAwesomeIcon className="partner-icon" icon={faToolbox} />
            </span>
            <div className="partner-description">
              <h4>Kovácsevics Ferenc - Kaputechnika</h4>
              <div>
                <a href="tel:+36 20 335 0667">
                  <p className="phone">+36 20 335 0667</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledPartners>
  )
}

export default Partners

const StyledPartners = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffff;
  padding: 2rem 0;
  height: 90vh;
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    width: 85%;
  }

  .title {
    color: rgb(57, 62, 70);
    font-size: 4rem;
    font-weight: 500;
    margin-top: 2rem;
    text-shadow: 3px 3px 3px rgb(134, 168, 164);
    text-align: center;
  }

  .alt-title {
    color: rgb(57, 62, 70);
    font-size: 2rem;
    font-weight: 200;
    margin-bottom: 2rem;
    text-align: center;
  }

  .partner-list {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 1rem;
    margin: 2rem 0;
  }

  .partner {
    display: flex;
    align-items: center;
    height: 110px;
    // border: 1px solid #006e8d;
    background: rgba(57, 62, 70, 1) 0%;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }

  .partner-icon {
    color: #ffff;
  }

  .partner:hover {
    .partner-icon {
      transform: scale(1.25);
    }
  }

  .partner-icon-container {
    background: #858484;
    min-width: 107px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .partner-icon {
    min-width: 40px;
    height: 100%;
    transition: transform 0.5s;
  }

  .partner-description {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    text-align: center;
    h4 {
      color: #ffff;
      font-size: 2rem;
      font-weight: 400;
    }

    p {
      color: #ffff;
      font-size: 1rem;
      font-weight: 200;
    }
  }
  @media only screen and (max-width: 1024px) {
    .container {
      grid-template-columns: 1fr;
      padding: 2rem;
    }
    .partner-list {
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
    }

    @media only screen and (max-width: 768px) {
      .container {
        padding: 1rem;
      }

      .title {
        font-size: 2.5rem;
      }

      .alt-title {
        font-size: 1.5rem;
      }
      .partner-description {
        h4 {
          font-size: 1.8rem;
          font-weight: 400;
        }

        p {
          font-size: 0.9rem;
          font-weight: 200;
        }
      }
    }
    @media only screen and (max-width: 480px) {
      .container {
        padding: 0;
      }
      .partner-description {
        h4 {
          font-size: 1.5rem;
          font-weight: 400;
        }

        p {
          font-size: 0.8rem;
          font-weight: 200;
        }
      }
    }
  }
`
