import React from "react"
import Navbar from "../components/Navbar"
import Home from "../components/Home"
import About from "../components/About"
import Services from "../components/Services"
import Discounts from "../components/Discounts"
import Gallery from "../components/Gallery"
import Partners from "../components/Partners"
import Contact from "../components/Contact"
import Seo from "../components/Seo"

import "../styles/global.css"

const Index = () => {
  return (
    <>
      <Seo title="FázisOhm" />
      <Navbar />
      <Home />
      <About />
      <Services />
      <Discounts />
      <Gallery />
      <Partners />
      <Contact />
    </>
  )
}

export default Index
