import React from "react"
import styled from "styled-components"
import logo from "../images/logoGray.png"

const Home = () => {
  return (
    <StyledHome id="index">
      <div className="home">
        <div className="logo-container">
          <img className="logo" src={logo} alt="Fázisohm logo" />
        </div>
        <h1>VILLANYSZERELÉS GYŐRBEN ÉS VONZÁSKÖRZETÉBEN</h1>
        <h3>TELJES KIVITELEZÉS A-Z-IG!</h3>
        <h4>VÁLLALJUK RÉGI ÉS ÚJ HÁZAK, LAKÁSOK KOMPLETT VILLANYSZERELÉSÉT</h4>
      </div>
    </StyledHome>
  )
}

export default Home

const StyledHome = styled.section`
  padding: 4rem 2rem;
  background: #ffffff;
  color: rgb(57, 62, 70);

  .home {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  h1,
  h3,
  h4 {
    color: rgb(57, 62, 70);
    text-align: center;
    // text-shadow: 3px 3px 3px #013f42;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1rem;
  }

  .logo {
    width: 400px;
    height: 280px;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 1024px) {
    h1 {
      font-size: 4rem;
    }
  }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .logo {
      width: 200px;
      height: auto;
      margin-bottom: 2rem;
    }
    h1 {
      font-size: 1.6rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    h4 {
      font-size: 0.8rem;
    }
  }
`
